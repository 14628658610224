<style lang="scss">
</style>

<template>
  <b-card no-body>
    <b-card-title class="font-weight-bolder m-2">Additional Information</b-card-title>
    <table class="table table-striped table-responsive-md mb-1">
      <tbody>
        <tr><td class="pr-0">State</td>
          <td v-if="device.state"><b>{{ device.state }}</b></td>
          <td v-else><b>N/A</b></td>
        </tr>
        <tr><td class="pr-0">Applied State</td><td><b>{{ device.applied_state }}</b></td></tr>
        <tr><td class="pr-0">User name</td><td><b>{{ device.user_name }}</b></td></tr>
        <tr><td class="pr-0">Enrollment token name</td><td><b>{{ device.enrollment_token_name }}</b></td></tr>
        <tr><td class="pr-0">Ownership</td><td><b>{{ device.ownership }}</b></td></tr>
        <tr><td class="pr-0">Management mode</td><td><b>{{ device.management_mode }}</b></td></tr>
        <tr><td class="pr-0">Keyboard installed</td><td><b>{{ device.keyboard_installed | boolToYes }}</b></td></tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>

export default {
  props: {
    device: {
      required: true,
      type: Object,
    }
  },
}
</script>
