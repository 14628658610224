export default [
  {
    path: '/admin/devices',
    name: 'admin-devices',
    component: () => import('@/views/adminDevices/AdminDevices'),
    meta: {
      resource: 'All',
      action: 'Manage',
      pageTitle: 'All Devices',
      breadcrumb: [
        {
          text: 'Showing all tablets and phones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/devices/:uid',
    name: 'admin-device-view',
    props: true,
    component: () => import('@/views/adminDevices/viewDevice/ViewDevice.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device',
      breadcrumb: [
        {
          text: 'All Devices',
          to: { name: 'admin-devices' },
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/devices/:deviceUid/applications/:appListId',
    name: 'admin-device-app-list-view',
    props: route => ({deviceUid: route.params.deviceUid, appListId: Number(route.params.appListId), appPageNumber: Number(route.query.appPageNumber), isAdminPage: true}),
    component: () => import('@/views/devices/viewDevice/sections/applications/ViewDeviceApplicationList.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device Application List',
      breadcrumb(route){
        const deviceUid = route.params.deviceUid;
        return [
          {
            text: 'All Devices',
            to: {name: 'admin-devices'},
          },
          {
            text: 'Device',
            to: {name: 'admin-device-view', params: {uid: deviceUid}},
          },
          {
            text: 'Device Application List',
            active: true,
          },
        ]
      },
    },
  },
]
