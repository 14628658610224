<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="devices-page">
    <b-card v-if="device">
      <div class="row">
        <div class="col-lg-2 mb-1 mb-lg-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="TabletIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                Device Serial Number
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{device.hardware_info_serial_number}}
              </h4>
            </b-media-body>
          </b-media>
        </div>

        <div class="col-lg-2 mb-1 mb-lg-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="HardDriveIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                Enterprise UID
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                {{device.enterprise_uid}}
              </h4>
            </b-media-body>
          </b-media>
        </div>

        <div v-if="device.owner" class="col-lg-5">
          <div class="row">
            <div class="col-lg-5 mb-1 mb-lg-0">
              <router-link :to="{ name: 'owner-view', params: { uid: device.owner_uid } }">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="KeyIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Owner Identifier
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      {{device.owner.identifier}}
                    </h4>
                  </b-media-body>
                </b-media>
              </router-link>
            </div>

            <div class="col-lg-7 mb-1 mb-lg-0">
              <router-link :to="{ name: 'owner-view', params: { uid: device.owner_uid } }">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="UserIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Owner Additional Information
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      {{device.owner.additional_info}}
                    </h4>
                  </b-media-body>
                </b-media>
              </router-link>
            </div>
          </div>
        </div>
        <div v-else class="col-lg-5 mb-1 mb-lg-0">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="KeyIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                Owner Identifier
              </b-card-text>
              <h4 class="font-weight-bolder mb-0">
                Device has no owner
              </h4>
            </b-media-body>
          </b-media>
        </div>

        <div class="col-lg-2">
          <b-button @click="switchEnterprise()" block variant="success" class="manage-btn" >Open in Enterprise</b-button>
        </div>
      </div>
    </b-card>

    <div v-if="device && !device.is_device_secure" class="alert alert-danger p-2">
      <i class="fas fa-exclamation-circle" />
      ALERT The device is not secure
      <i class="fas fa-exclamation-circle" />
    </div>
    <div v-if="device && device.policy_override !== ''" class="alert alert-warning p-2">
      <i class="fas fa-exclamation-triangle" />
      WARNING The policy on this device has been isolated. It may not receive further updates
      <i class="fas fa-exclamation-triangle" />
    </div>
    <div v-if="device && !device.owner && device.disabled" class="alert alert-warning p-2">
      <i class="fas fa-exclamation-triangle" />
      WARNING This device has been disabled
      <i class="fas fa-exclamation-triangle" />
      <div v-if="device.disabled_notes !== ''" class="pl-2 pt-1">Reason For Disabling: {{ device.disabled_notes }}</div>
    </div>
    <div v-if="device && device.status === 'provisioning'" class="alert alert-info p-2">
      <i class="fas fa-info-circle" />
      <span v-if="device.latest_data.status_change_message !== ''" class="pl-1 pr-1">Reason in provisioning: {{ device.latest_data.status_change_message }}</span>
      <i class="fas fa-info-circle" />
    </div>
    <section v-if="!loading">
      <div v-if="device">
        <b-tabs content-class="my-0">

          <b-tab :active="!showDeviceApps">
            <template slot="title"><feather-icon icon="TabletIcon" />Status</template>
            <device-details :device="device"></device-details>
          </b-tab>

          <b-tab>
            <template slot="title"><feather-icon icon="GlobeIcon" />Compliance</template>
            <device-compliance-records :device="device" :is-admin-page="true"></device-compliance-records>
          </b-tab>

          <b-tab id="title">
            <template slot="title"><feather-icon icon="SaveIcon" />Data History</template>
            <device-data-history class="mt-2" :device="device" :is-admin-page="true"></device-data-history>
          </b-tab>

          <b-tab id="title">
            <template slot="title"><feather-icon icon="AirplayIcon" />Device Activities</template>
            <device-activities class="mt-2" :device="device" :is-admin-page="true"></device-activities>
          </b-tab>

          <b-tab id="title">
            <template slot="title"><feather-icon icon="UserIcon" />Owner Activities</template>
            <device-owner-activities class="mt-2" :device="device" :is-admin-page="true"></device-owner-activities>
          </b-tab>

          <b-tab :active="showDeviceApps">
            <template slot="title"><feather-icon icon="StarIcon" />Device Applications</template>
            <device-application-list-records :device="device" :is-admin-page="true" :page-number="deviceAppsPageNumber"></device-application-list-records>
          </b-tab>

          <b-tab title="Other">
            <template slot="title"><feather-icon icon="MoreHorizontalIcon" />Other</template>
            <device-other-info :device="device"></device-other-info>
          </b-tab>
        </b-tabs>
      </div>
      <div v-else>Could not get device</div>
    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading devices"></b-spinner>
    </section>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeviceDetails from '@/views/devices/viewDevice/sections/dataHistory/DeviceDetails.vue'
import {BAvatar} from "bootstrap-vue";
import DeviceOtherInfo from "@/views/devices/viewDevice/sections/other/DeviceOtherInfo.vue";
import AdminDeviceService from "@/services/AdminDeviceService";
import DeviceComplianceRecords from "@/views/devices/viewDevice/sections/compliance/DeviceComplianceRecords.vue";
import DeviceDataHistory from "@/views/devices/viewDevice/sections/dataHistory/DeviceDataHistory.vue";
import auth from "@/auth/auth";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import {mapState} from "vuex";
import DeviceOwnerActivities from "@/views/devices/viewDevice/sections/ownerActivities/DeviceOwnerActivities.vue";
import DeviceActivities from "@/views/devices/viewDevice/sections/deviceActivities/DeviceActivities.vue";
import DeviceApplicationListRecords from "@/views/devices/viewDevice/sections/applications/DeviceApplicationListRecords.vue";

export default {
  name: 'Device',
  components: {
    DeviceActivities, DeviceOwnerActivities,
    DeviceApplicationListRecords,
    DeviceOtherInfo,
    BAvatar,
    DeviceComplianceRecords,
    DeviceDataHistory,
    DeviceDetails,
  },
  props: {
    uid: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      device: null,
      showDeviceApps: false,
      deviceAppsPageNumber: 0,
    };
  },
  computed: {
    ...mapState({
      user: state => state.user.currentUser,
    }),
  },
  mounted() {
    this.getDevice();
  },
  methods: {
    getDevice() {
      this.loading = true;
      AdminDeviceService.getDevice(this.uid).then(res => {
        this.device = res.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get device, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }).finally(() => {
        this.loading = false;
      });
    },
    switchEnterprise() {
      if (this.user.enterprise_id === this.device.enterprise_uid) {
        this.$router.push({name: 'device-view', params: {uid: this.uid}})
        return
      }
      auth.switch({
        enterprise_uid: this.device.enterprise_uid,
      })
        .then(async response => {
          auth.setToken(response.data.accessToken)
          // Response must be authorised so we should be able to use the refresh token to fetch the user details
          await auth.refreshCurrentUser()
          // Make sure they have some permissions
          if (auth.getCurrentUser().permissions == null) {
            this.loginError = 'You do not have the right Role or Permissions. Please contact your Site Administrator'
            return
          }

          this.$toast({
              component: ToastificationContent,
              position: 'top-center',
              props: {
                title: 'Switch Successful',
                icon: 'HardDriveIcon',
                variant: 'success',
                text: 'You have now switched enterprises',
              },
            },
            {
              timeout: 2000,
              position: 'top-center',
            })

            await this.$router.push({name: 'device-view', params: {uid: this.uid}})
        }, error => {
          let message
          if (error.error) {
            message = error.error
          } else {
            message = error
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Switch Failed',
              icon: 'HardDriveIcon',
              variant: 'danger',
              text: `The switch failed. Please try again. ${message}`,
            },
          })
        })
    },
  },
  beforeRouteEnter(to, from, next) {
    if(from.name === 'admin-device-app-list-view') {
      next(vm => {
        vm.showDeviceApps = true
        vm.deviceAppsPageNumber = from.query.appPageNumber
      });
      return
    }
    next(vm => {
      vm.showDeviceApps = false
    });
  },
};
</script>
