<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Device')">
    <b-card class="mb-0 " no-body>

      <div class="d-flex justify-content-between align-items-center mb-1 mt-1">
        <p class="font-weight-bolder px-1 pt-1">Device Owner Activities</p>

        <div class="d-flex align-items-center pr-1">
          <b-input-group class="pr-1">
            <b-form-input v-model="filters.search" class="d-inline-block"
                          v-on:keydown.enter="getOwnerActivities" placeholder="Search..."/>
            <b-input-group-append>
              <b-button v-if="filters.search" @click="clearSearch" variant="warning">Clear</b-button>
              <b-button @click="getOwnerActivities" variant="primary">Search</b-button>
            </b-input-group-append>
          </b-input-group>

          <v-select v-model="filters.app"
                    :reduce="app => app.code"
                    :options="appOptions" class="invoice-filter-select"
                    placeholder="Filter by App" @input="getOwnerActivities">

            <template #selected-option="{ label }">
                          <span class="text-truncate overflow-hidden">
                            {{ label }}
                          </span>
            </template>
          </v-select>
        </div>
      </div>


      <section>
        <b-table
          class="data-table"
          hover
          responsive
          :items="records"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreActivities" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="p-2 pl-3" v-if="total_count === 0">No records found</b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeviceService from '@/services/DeviceService';
import vSelect from 'vue-select'
import AdminDeviceService from "@/services/AdminDeviceService";
import ApplicationsService from "@/services/ApplicationsService";

export default {
  name: 'DeviceOwnerActivities',
  components: {
    vSelect,
  },
  props: {
    device: {
      required: true,
      type: Object,
    },
    isAdminPage: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      selectedRecord: null,
      records: [],
      appOptions: [],
      headers: [
        {
          key: 'created_at',
          label: 'Created at',
        },
        {
          key: 'owner.identifier',
          label: 'Owner',
        },
        {
          key: 'app_name',
          label: 'App Name',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'description',
          label: 'Description',
        },
      ],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        app: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getApplications();
    this.getOwnerActivities();
  },
  methods: {
    async getApplications() {
      const appRequest = await ApplicationsService.getApplications();
      if (appRequest.data) {
        appRequest.data.forEach(item => {
          this.appOptions.push({
            label: item.name,
            code: item.package_name,
          })
        })
      }
    },
    getMoreActivities(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getOwnerActivities();
    },
    getOwnerActivities() {
      let getRecordsPromise;

      if (this.isAdminPage) {
        getRecordsPromise = AdminDeviceService.getDeviceOwnerActivities(this.device.uid, this.filters)
      } else {
        getRecordsPromise = DeviceService.getDeviceOwnerActivities(this.device.uid, this.filters)
      }

      getRecordsPromise.then(res => {
        this.records = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get device history records, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    clearSearch() {
      this.filters.search = '';
      this.getOwnerActivities()
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 300px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 200px;
  }
}
</style>
