import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/admin/devices';

export default {
  getAdminDevices(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getDevice(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  getDeviceComplianceRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/compliance${queryString}`);
  },
  getDeviceDataHistoryRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/data${queryString}`);
  },
  getDeviceActivities(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/activities${queryString}`);
  },
  getDeviceOwnerActivities(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/owner-activities${queryString}`);
  },

  getDeviceApplicationListRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/applications${queryString}`);
  },
  getDeviceApplicationList(uid, listId) {
    return axios.get(`${baseUrl}/${uid}/applications/${listId}`);
  },
};
