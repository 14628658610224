<style lang="scss"></style>

<template>
  <div>
    <section>
      <b-card class="" title="Overview">
        <section class="d-flex flex-wrap align-items-start justify-content-between">
          <p>Factory Installed: <b>{{ factory_installed_count }}</b></p>
          <p>App Store Installed: <b>{{ app_store_installed_count }}</b></p>
          <p>Publish time: <b>{{ created_at | formatDateTime }}</b></p>
        </section>
      </b-card>


      <b-card no-body class="mb-0">
        <div class="row px-2 py-1"/>
        <section>
          <div class="table-responsive">
            <b-table
              class="data-table"
              :responsive="true"
              hover
              :items="applications"
              :fields="headers"
              empty-text="No applications"
            />
          </div>
        </section>
      </b-card>
    </section>
  </div>
</template>

<script>
  import AdminDeviceService from "@/services/AdminDeviceService";
  import DeviceService from "@/services/DeviceService";

  export default {
    components: {},
    props: {
      isAdminPage: {
        required: true,
        type: Boolean
      },
      appListId: {
        required: true,
        type: Number
      },
      deviceUid: {
        required: true,
        type: String,
      },
      appPageNumber: {
        required: false,
        type: Number,
        default: 0
      },
    },
    data() {
      return {
        headers: [
          {
            key: 'applicationSource',
            sortable: true,
          },
          {
            key: 'displayName',
            sortable: true,
          },
          {
            key: 'installerPackageName',
            sortable: true,
          },
          {
            key: 'packageName',
            sortable: true,
          },
          {
            key: 'state',
            sortable: true,
          },
          {
            key: 'userFacingType',
            sortable: true,
          },
          {
            key: 'versionCode',
            sortable: true,
          },
          {
            key: 'versionName',
            sortable: true,
          },
        ],
        applications: [],
        factory_installed_count: 0,
        app_store_installed_count: 0,
        created_at: '',
      };
    },
    mounted() {
      this.getApplicationList();
    },
    methods: {
      getApplicationList() {
        let getRecordsPromise;

        if (this.isAdminPage) {
          getRecordsPromise = AdminDeviceService.getDeviceApplicationList(this.deviceUid, this.appListId)
        } else {
          getRecordsPromise = DeviceService.getDeviceApplicationList(this.deviceUid, this.appListId)
        }

        getRecordsPromise.then(res => {
          this.applications = res.data.application_reports;
          this.factory_installed_count = res.data.factory_installed_count;
          this.app_store_installed_count = res.data.app_store_installed_count;
          this.created_at = res.data.created_at;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get device application list records, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
    },
  };
</script>
