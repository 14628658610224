<script>
import CalendarEventsService from "@/services/CalendarEventsService";
import helperService from "@/services/HelperService";
import moment from "moment/moment";

export default {
  name: "OwnerCalendarEventModal",
  props: {
    owner: {
      type: Object,
      required: true,
    },
    event: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      loading: false,
      calendarEvent: {
        title: '',
        content: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
      },
    };
  },
  computed: {
    updating() {
      return this.event;
    },
  },
  watch: {
    event: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.calendarEvent = {...newVal};

          // Initialize start_time and end_time from start_date and end_date
          if (newVal.start_date) {
            this.calendarEvent.start_time = moment(newVal.start_date).format('HH:mm');
            this.calendarEvent.start_date = moment(newVal.start_date).format('YYYY-MM-DD')
          }
          if (newVal.end_date) {
            this.calendarEvent.end_time = moment(newVal.end_date).format('HH:mm');
            this.calendarEvent.end_date = moment(newVal.end_date).format('YYYY-MM-DD')
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async submitCalendarEvent() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const startDateTime = moment(`${this.calendarEvent.start_date}T${this.calendarEvent.start_time}`);
      const endDateTime = moment(`${this.calendarEvent.end_date}T${this.calendarEvent.end_time}`);

      const dto = {
        title: this.calendarEvent.title,
        content: this.calendarEvent.content,
        start_date: startDateTime.toDate(),
        end_date: endDateTime.toDate(),
        model: "Owner",
        model_uid: this.owner.uid,
      };

      try {
        if (this.updating) {
          await CalendarEventsService.updateCalendarEvent(this.event.id, dto);
        } else {
          await CalendarEventsService.createCalendarEvent(dto);
        }
        helperService.showNotfySuccess(this.$toast, `Calendar event successfully ${this.updating ? 'updated' : 'created'}.`)
        this.$emit('refresh');
      } catch (err) {
        let msg = `Error ${this.updating ? 'updating' : 'creating'} event`;

        const res = err.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, msg);
      } finally {
        this.loading = false;
      }
    },
    async deleteEvent() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      try {
        await CalendarEventsService.deleteCalendarEvent(this.event.id);
        helperService.showNotfySuccess(this.$toast, `Calendar event successfully deleted.`)
        this.$emit('refresh');
      } catch (err) {
        let msg = 'Error deleting event, please try again.'

        const res = err.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, msg);
      } finally {
        this.loading = false;
      }
    },
  },
}
</script>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(submitCalendarEvent)">
        <b-form-group>
          <!--Title-->
          <b-input-group class="mb-1 d-flex flex-column mt-1">
            <label>
              Title
            </label>
            <validation-provider
              v-slot="validationContext"
              name="title"
              :rules="{required: true}"
            >
              <b-form-input
                v-model="calendarEvent.title"
                placeholder="Enter an event title..."
                :state="getValidationState(validationContext)"
              />
            </validation-provider>
          </b-input-group>

          <!--Content-->
          <b-input-group class="mb-1 d-flex flex-column mt-1">
            <label>
              Content
            </label>

            <validation-provider
              v-slot="validationContext"
              name="content"
              :rules="{required: true}"
            >
              <b-form-textarea
                v-model="calendarEvent.content"
                placeholder="Enter content for the event..."
                :state="getValidationState(validationContext)"
              />
            </validation-provider>
          </b-input-group>

          <!--Start Date-->
          <validation-provider
            v-slot="validationContext"
            name="start_date"
            :rules="{required: true}"
          >
            <label>Start Date</label>
            <b-datepicker
              v-model="calendarEvent.start_date"
              class="w-100 mb-1"
              :state="getValidationState(validationContext)"
            />
          </validation-provider>

          <!--End Date-->
          <validation-provider
            v-slot="validationContext"
            name="end_date"
            :rules="{required: true, after_or_equal_date: {startDate: calendarEvent.start_date}}"
          >
            <label>End Date</label>
            <b-datepicker
              v-model="calendarEvent.end_date"
              class="w-100 mb-1"
              :state="getValidationState(validationContext)"
            />
          </validation-provider>

          <!--Start Time-->
          <validation-provider
            v-slot="validationContext"
            name="Start time"
            rules="required"
          >
            <label>Start Time</label>
            <b-timepicker
              v-model="calendarEvent.start_time"
              :disabled="!calendarEvent.start_date || !calendarEvent.end_date"
              :state="getValidationState(validationContext)"
              class="w-100 mb-1"
            />
          </validation-provider>

          <!--End Time-->
          <validation-provider
            v-slot="validationContext"
            name="end_time"
            :rules="{
              required: true,
              after_or_equal_time: {
                startTime: calendarEvent.start_time,
                startDate: calendarEvent.start_date,
                endDate: calendarEvent.end_date,
              }
            }"
          >
            <label>End Time</label>
            <b-timepicker
              v-model="calendarEvent.end_time"
              :disabled="!calendarEvent.start_date || !calendarEvent.end_date"
              :state="getValidationState(validationContext)"
              class="w-100 mb-1"
            />
          </validation-provider>

          <!--Buttons-->
          <b-input-group class="d-flex flex-row-reverse mt-3">
            <b-btn variant="primary" type="submit" :disabled="invalid">
              {{ updating ? 'Update ' : 'Create ' }}
            </b-btn>
            <b-btn
              v-if="updating && $can('Delete', 'CalendarEvents')"
              variant="danger"
              class="d-inline mr-1"
              :disabled="invalid"
              @click="deleteEvent"
            >
              Delete
            </b-btn>
            <b-btn class="d-inline mr-1" @click="$emit('close')">
              Close
            </b-btn>
          </b-input-group>
        </b-form-group>
      </b-form>
    </validation-observer>
  </div>
</template>


