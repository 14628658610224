<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Device')">
    <b-card class="mb-0 " no-body>
      <div class="pb-1 mt-1" style="display: flex; align-items: center; justify-content: space-between">
        <p class="font-weight-bolder px-1 pt-1">Device Activities</p>
        <b-input-group class="pr-1" style="width: 30%">
          <b-form-input
            v-on:keydown.enter="getActivities"
            v-model="filters.search"
            class="d-inline-block"
            placeholder="Search for an activity..."
          />
          <b-input-group-append>
            <b-button v-if="filters.search" @click="clearSearch" variant="warning">Clear</b-button>
            <b-button @click="getActivities" variant="primary">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          responsive
          :items="records"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>

          <template #cell(from_device)="data">
            {{ data.item.from_device | boolToYes }}
          </template>

        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreActivities" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="p-2 pl-3" v-if="total_count === 0">No records found</b-col>
        </b-row>
      </section>
    </b-card>
  </div>
</template>
<!--eslint-enable-->

<script>
import DeviceService from '@/services/DeviceService';
import AdminDeviceService from "@/services/AdminDeviceService";

export default {
  name: 'DeviceActivities',
  props: {
    device: {
      required: true,
      type: Object,
    },
    isAdminPage: {
      required: true,
      type: Boolean
    },
  },
  data() {
    return {
      selectedRecord: null,
      records: [],
      headers: [
        {
          key: 'created_at',
          label: 'Created at',
        },
        {
          key: 'action',
          label: 'Action',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'from_device',
          label: 'From device',
        },
      ],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 10,
      },
      total_count: 0,
      searchVal: '',
    };
  },
  mounted() {
    this.getActivities();
  },
  methods: {
    getMoreActivities(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getActivities();
    },
    getActivities() {
      let getRecordsPromise;

      if (this.isAdminPage) {
        getRecordsPromise = AdminDeviceService.getDeviceActivities(this.device.uid, this.filters)
      } else {
        getRecordsPromise = DeviceService.getDeviceActivities(this.device.uid, this.filters)
      }

      getRecordsPromise.then(res => {
        this.records = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get device history records, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    clearSearch() {
      this.filters.search = '';
      this.getActivities()
    },
  },
};
</script>
