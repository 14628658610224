import {alpha_dash as route} from "vee-validate/dist/rules";

export default [
  {
    path: '/devices',
    name: 'devices',
    component: () => import('@/views/devices/Devices.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Devices',
      breadcrumb: [
        {
          text: 'Showing tablets and phones under your control',
          active: true,
        },
      ],
    },
  },
  {
    path: '/devices/device/:uid',
    name: 'device-view',
    props: true,
    component: () => import('@/views/devices/viewDevice/ViewDevice.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device',
      breadcrumb: [
        {
          text: 'All Devices',
          to: { name: 'devices' },
        },
        {
          text: 'Device',
          active: true,
        },
      ],
    },
  },
  {
    path: '/devices/:deviceUid/applications/:appListId',
    name: 'device-app-list-view',
    props: route => ({deviceUid: route.params.deviceUid, appListId: Number(route.params.appListId), appPageNumber: Number(route.query.appPageNumber), isAdminPage: false}),
    component: () => import('@/views/devices/viewDevice/sections/applications/ViewDeviceApplicationList.vue'),
    meta: {
      resource: 'Device',
      action: 'Read',
      pageTitle: 'Device Application List',
      breadcrumb(route){
        const deviceUid = route.params.deviceUid;
        return [
          {
            text: 'All Devices',
            to: {name: 'devices'},
          },
          {
            text: 'Device',
            to: {name: 'device-view', params: {uid: deviceUid}},
          },
          {
            text: 'Device Application List',
            active: true,
          },
        ]
      },
    },
  },
]
