import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/devices';

export default {
  getDevices(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getDeviceComplianceRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/compliance${queryString}`);
  },
  getDeviceDataHistoryRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/data${queryString}`);
  },
  getDevice(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  stats() {
    return axios.get(`${baseUrl}/stats`);
  },
  rebootDevice(uid) {
    return axios.put(`${baseUrl}/${uid}/reboot`);
  },
  lockDevice(uid) {
    return axios.put(`${baseUrl}/${uid}/lock`);
  },
  deleteDevice(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  disableDevice(uid, notes) {
    return axios.post(`${baseUrl}/${uid}/disable`, {'notes': notes});
  },
  enableDevice(uid, confirmed) {
    return axios.post(`${baseUrl}/${uid}/enable`, {'device_in_hands': confirmed});
  },
  btRegisterDevice(uid) {
    return axios.put(`${baseUrl}/${uid}/assignment/bt-register`);
  },
  resendPassword(uid) {
    return axios.put(`${baseUrl}/${uid}/password`);
  },

  // Super admin only endpoint
  isolateDevice(uid) {
    return axios.post(`/admin/devices/${uid}/isolate`);
  },
  getQrCodeEnrollment() {
    return axios.get(`${baseUrl}/enrollment`);
  },

  // Attach to owner
  attachOwner(deviceUid, ownerUid) {
    return axios.post(`${baseUrl}/${deviceUid}/assignment`, {'owner_uid': ownerUid});
  },

  detachOwner(deviceUid) {
    return axios.delete(`${baseUrl}/${deviceUid}/assignment`);
  },

  getTotp(timestamp) {
    return axios.post(`${baseUrl}/totp`, { timestamp });
  },

  enableOtaUpdates(uid) {
    return axios.put(`${baseUrl}/${uid}/ota-enable`);
  },

  disableOtaUpdates(uid) {
    return axios.put(`${baseUrl}/${uid}/ota-disable`);
  },

  getDeviceActivities(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/activities${queryString}`);
  },
  getDeviceOwnerActivities(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/owner-activities${queryString}`);
  },

  getDeviceApplicationListRecords(uid, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/${uid}/applications${queryString}`);
  },
  getDeviceApplicationList(uid, listId) {
    return axios.get(`${baseUrl}/${uid}/applications/${listId}`);
  },
};
